import React from "react";
import {
  Divider,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import DashboardItem from "./DashboardItem";
import { REPORTED_STATUS } from "../Dashboard";

export default function TelegramProcessing({ data }) {
  const { t } = useTranslation(["dashboard"]);


  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Telegram Processing")}>
        {/* F7 */}
        <DashboardItem title={'F7: ' + t("dashboard:Duplicate serial number entered - Dispo")} reportedStatuses={[REPORTED_STATUS.F7]} />

        <Divider />

        {/* F4 */}
        <DashboardItem title={'F4: ' + t("dashboard:Telegram Decryption Problem")} reportedStatuses={[REPORTED_STATUS.F4]} />

        <Divider />

        {/* F10 */}
        <DashboardItem title={'F10: ' + t("dashboard:No telegram received - input error device master")} reportedStatuses={[REPORTED_STATUS.F10]} />

        <Divider />

        {/* F11 */}
        <DashboardItem title={'F11: ' + t("dashboard:Telegram could not be processed - Please contact support")} reportedStatuses={[REPORTED_STATUS.F11]} />
      </Card>
    </Grid>
  );
}
