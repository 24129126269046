import React from "react";
import { Divider, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import DashboardItem from "./DashboardItem";
import { REPORTED_STATUS } from "../Dashboard";

export default function DeviceErrors({ data }) {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Device Errors")}>
        {/* F6 */}
        <DashboardItem title={'F6: ' + t("dashboard:Declining values measured - Dispo")} reportedStatuses={[REPORTED_STATUS.F6]} />

        <Divider />

        {/* F8 */}
        <DashboardItem title={'F8: ' + t("dashboard:Device error - Dispo")} reportedStatuses={[REPORTED_STATUS.F8]} />
      </Card>
    </Grid>
  );
}
