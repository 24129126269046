import React, { useState } from 'react';
import { Grid, Box, Typography, Button, CircularProgress } from '@material-ui/core';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import RouterIcon from '@material-ui/icons/Router';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ApartmentIcon from '@material-ui/icons/Apartment';
import gql from "graphql-tag";
import Card from "./Card";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useMutation } from "@apollo/react-hooks";
import BuildingModel from '../../model/building';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { useTranslation } from "react-i18next";

const GET_COVERAGE_ISSUES = gql`
  mutation getCoverageIssues {
    coverageIssues
  }
`

const calculateGrowth = (total, lastSixMonthsDelta) => {
  if (total <= 0) {
    return 0; // No growth if total is zero or negative
  }
  if (lastSixMonthsDelta <= 0) {
    return 0; // No growth if delta is zero or negative
  }
  if (lastSixMonthsDelta >= total) {
    return 100; // Maximum growth if delta is equal to or greater than total
  }

  const growth = (lastSixMonthsDelta / (total - lastSixMonthsDelta)) * 100;
  return parseFloat(growth.toFixed(1));
};

function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

export default function Status({ data }) {
  const { t } = useTranslation(["dashboard"]);
  const [loading, setLoading] = useState(false);
  const [getCoverageIssues] = useMutation(GET_COVERAGE_ISSUES);

  const {
    gateways_count,
    last_six_months_gateways_count,
    sensors_count,
    wireless_sensors_count,
    last_six_months_wireless_sensors_count,
    buildings_count,
    last_six_months_buildings_count,
    apartments_count,
    last_six_months_apartments_count,
  } = data;

  const items = [
    {
      icon: <DeviceHubIcon />,
      text: `${t("dashboard:Gateways (growth rate in last 6 months)")}: 
      ${isValidNumber(gateways_count) ? gateways_count : 'N/A'}
      (${isValidNumber(gateways_count) && isValidNumber(last_six_months_gateways_count) ? calculateGrowth(gateways_count, last_six_months_gateways_count) : 'N/A'}%)`,
    },
    {
      icon: <RouterIcon />,
      text: `${t(
        "dashboard:Wireless Devices (growth rate in last 6 months)"
      )}: 
      ${isValidNumber(wireless_sensors_count) ? wireless_sensors_count : 'N/A'}
      (${isValidNumber(wireless_sensors_count) && isValidNumber(last_six_months_wireless_sensors_count) ? calculateGrowth(wireless_sensors_count, last_six_months_wireless_sensors_count) : 'N/A'}%)`,
    },
    {
      icon: <SettingsRoundedIcon />,
      text: `${t("dashboard:Devices Total")}: ${isValidNumber(sensors_count) ? sensors_count : 'N/A'}`,
    },
    {
      icon: <HomeWorkIcon />,
      text: `${t("dashboard:Building (Growth Rate in last 6 months)")}: 
      ${isValidNumber(buildings_count) ? buildings_count : 'N/A'}      
      (${isValidNumber(buildings_count) && isValidNumber(last_six_months_buildings_count) ? calculateGrowth(buildings_count, last_six_months_buildings_count) : 'N/A'}%)`,
    },
    {
      icon: <ApartmentIcon />,
      text: `${t("dashboard:WE (Growth Rate in last 6 months)")}: 
      ${isValidNumber(apartments_count) ? apartments_count : 'N/A'}      
      (${isValidNumber(apartments_count) && isValidNumber(last_six_months_apartments_count) ? calculateGrowth(apartments_count, last_six_months_apartments_count) : 'N/A'}%)`,
    },
  ];

  const handleDownload = async () => {
    setLoading(true);
    try {
      const { data } = await getCoverageIssues();

      console.log(data)

      const formattedData = data.coverageIssues.map(({ street, house_number, city, postalcode, external_id, Report }) => ({
        'Date of Statistic': Report[0]?.ReportStats?.date,
        'Building External Number': external_id,
        'Building Address': BuildingModel.fullAddress(street, house_number, city, postalcode),
        'Device Serial Number': Report[0]?.ReportStats?.serials_count,
        'Total number of devices': Report[0]?.ReportStats?.sensors_count,
        'Total number of wireless devices': Report[0]?.ReportStats?.radio_collected_count,
        'Total number of devices received in latest report': Report[0]?.ReportStats?.sensors_with_total_telegrams_count,
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Coverage Issues');

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(dataBlob, "BuildingsCoverageIssues-" + ((new Date()).toISOString()) + ".xlsx");
    } catch (error) {
      console.error("Error fetching coverage issues data:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Grid item xs={12} md={9}>
      <Card title={t("dashboard:Status")}>
        <Grid container>
          <Grid item md={4}>
            {items.map((item, index) => (
              <Box display="flex" alignItems="center" mb={1} key={index}>
                {item.icon}
                <Typography variant="body4" style={{ marginLeft: 8 }}>
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Grid>
          <Grid item md={8}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Box>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  {t("dashboard:Buildings with Coverage below 90%")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t("dashboard:Number of Buildings")}: {isValidNumber(data.building_coverage_count) ? data.building_coverage_count : 'N/A'}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDownload}
                endIcon={loading ? <CircularProgress size={24} /> : <DownloadIcon />}
                disabled={loading || !isValidNumber(data.building_coverage_count) || data.building_coverage_count === 0}
              >
                {loading ? t("dashboard:loading") : t("dashboard:excel")}
              </Button>
            </Box>

          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
