import React from "react";
import { Grid, Box, Typography, Button, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import DashboardItem from "./DashboardItem";
import { REPORTED_STATUS } from "../Dashboard";

export default function DeviceReception({ data }) {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Device Reception")}>
        {/* F0 */}
        <DashboardItem title={'F0: ' + t("dashboard:Gateway is not sending data")} reportedStatuses={[REPORTED_STATUS.F0]} />

        <Divider />

        {/* F1 */}
        <DashboardItem title={'F1: ' + t("dashboard:Never received a telegram")} reportedStatuses={[REPORTED_STATUS.F1]} />

        <Divider />

        {/* F2 */}
        <DashboardItem title={'F2: ' + t("dashboard:No Telegram Received longer than 18M - Dispo")} reportedStatuses={[REPORTED_STATUS.F2]} />

        <Divider />

        {/* F3 */}
        <DashboardItem title={'F3: ' + t("dashboard:No Telegram Received")} reportedStatuses={[REPORTED_STATUS.F3A, REPORTED_STATUS.F3B, REPORTED_STATUS.F3C]} />

        <Divider />

        {/* F5 */}
        <DashboardItem title={'F5: ' + t("dashboard:Measurement for Billing End Period missing")} reportedStatuses={[REPORTED_STATUS.F5]} />
      </Card>
    </Grid>
  );
}
