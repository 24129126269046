import React, {useState} from 'react';
import fp from 'lodash/fp';
import useReactRouter from 'use-react-router';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import User from './User';
import Table from '../components/Table';

const GET_USERS = gql`
  query getUsers($ids: [ID]) {
    users(ids: $ids) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
      }
      organization {
        id
        name
      }
    }
  }
`;

const ADD_USER = gql`
  mutation addUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $role: Int!
  ) {
    addUser(
      email: $email
      password: $password
      role: $role
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const Content = ({match}) => {
  const {t} = useTranslation(['user']);
  const {history} = useReactRouter();
  const {loading, error, data, refetch} = useQuery(GET_USERS, {});
  const [addUser] = useMutation(ADD_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const [message, setMessage] = useState(null);

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const handleAddUser = async (newData) => {
    try {
      await addUser({
        variables: {
          email: newData.email,
          password: newData.password,
          firstName: newData.profile.first_name,
          lastName: newData.profile.last_name,
          phone: newData.profile.phone,
          role: Number(newData.role),
        },
      });
      setMessage({type: 'success', text: 'User added successfully!'});
      refetch();
    } catch (err) {
      setMessage({type: 'error', text: 'Failed to add user.'});
    }
  };

  const handleDeleteUser = async (oldData) => {
    try {
      await deleteUser({
        variables: {
          id: oldData.id,
        },
      });
      setMessage({type: 'success', text: 'User deleted successfully!'});
      refetch();
    } catch (err) {
      setMessage({type: 'error', text: 'Failed to delete user.'});
    }
  };

  const columns = [
    {
      title: t('user:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('user:E-mail'), field: 'email'},
    {
      title: t('user:First Name'),
      field: 'profile.first_name',
    },
    {
      title: t('user:Last Name'),
      field: 'profile.last_name',
    },
    {
      title: t('user:Organization'),
      field: 'user.organization',
      render: (rowData) => fp.get('organization.name', rowData),
    },
    {
      title: t('user:Phone'),
      field: 'profile.phone',
    },
    {
      title: t('user:Password'),
      field: 'password',
      render: () => '*************',
    },
    {
      title: t('user:Role'),
      field: 'role',
      lookup: t('user:roles'),
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="users"
        title=""
        isLoading={loading}
        columns={columns}
        data={loading ? [] : data.users}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        onRowClick={(event, rowData) => {
          history.push(`${match.path}/${rowData.id}`);
        }}
        editable={{
          onRowAdd: handleAddUser,
          onRowDelete: handleDeleteUser,
        }}
      />
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
      >
        {message && (
          <Alert onClose={() => setMessage(null)} severity={message.type}>
            {message.text}
          </Alert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};

export default function Users({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={User} />
    </React.Fragment>
  );
}
