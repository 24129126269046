import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import React from 'react';
import LoadComponent from '../components/LoadComponent';
import TelegramSearch from './Dashboard/TelegramSearch';
import Status from './Dashboard/Status';
import DeviceReception from './Dashboard/DeviceReception';
import TelegramProcessing from './Dashboard/TelegramProcessing';
import DeviceErrors from './Dashboard/DeviceErrors';

const GET_ORGANIZATION_STATS = gql`
	query getDashboardStats {
		organizationStats {
			gateways_count,
			last_six_months_gateways_count
			sensors_count
			wireless_sensors_count
			last_six_months_wireless_sensors_count
			buildings_count
			last_six_months_buildings_count
			apartments_count
			last_six_months_apartments_count
			building_coverage_count
		}
	}
`;

export const REPORTED_STATUS = {
	NO_ERROR: 0,
	F0: 1,
	F1: 10,
	F2: 20,
	F3A: 31,
	F3B: 32,
	F3C: 33,
	F4: 40,
	F5: 50,
	F6: 60,
	F7: 70,
	F8: 80,
	F9: 90,
	F10: 100,
	F11: 110
};

export default function Dashboard() {
	const { data, loading, error } = useQuery(GET_ORGANIZATION_STATS);
	if (error) {
		return <div>Error</div>;
	}
	const organizationStats = data?.organizationStats ?? {};

	return (
		<LoadComponent>
			<Grid container spacing={3}>
				<Status data={organizationStats} />
				<TelegramSearch />
				<DeviceReception data={organizationStats} />
				<TelegramProcessing data={organizationStats} />
				<DeviceErrors data={organizationStats} />
			</Grid >
		</LoadComponent>
	);
}