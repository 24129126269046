import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import fp from 'lodash/fp';
import { Switch, Route, Link } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MoneyIcon from '@material-ui/icons/Money';
import ViewListIcon from '@material-ui/icons/ViewList';
import PieChartIcon from '@material-ui/icons/PieChart';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';
import PublishIcon from '@material-ui/icons/Publish';
import Check from '@material-ui/icons/Check';
import Remove from '@material-ui/icons/Remove';

import downloadCsv from '../lib/downloadCsv';
import NewReport from './NewReport';
import Table, { useTableStyles } from '../components/Table';
import IfenaButton from '../components/Button';
import ReportModel from '../model/report';
import { TelegramDetailPanel } from '../components/TelegramDetailPanel';
import ResolveButton from './Report/ResolveButton';
import ErrorsList from './Report/ErrorsList';
import { Checkbox } from '@material-ui/core';
import TelegramModel from '../model/telegram';
import CommentsTable from '../components/Report/CommentsTable';
import data from '../lib/data';
import { useMe } from '../reducers/me';
import UserModel from '../model/user';

const GET_REPORT = gql`
  query getReport($id: ID!) {
    reports(ids: [$id]) {
      id
      status
      created_at
      updated_at
      date_submitted
      reading_date
      set_date
      comment
      comment_operator
      sensors_count
      serials_count
      sensors_with_telegrams_count
      sensors_with_total_telegrams_count
      sensor_completion
      serial_completion
      telegram_completion
      total_telegram_completion
      radio_collected_count
      hand_collected_count
      last_exports
      operator {
        id
        email
      }
      building {
        id
        external_id
        street
        house_number
        postalcode
        alternate_address
        city
        comment
        set_date
        building_sensors_count
        apartment_sensors_count
        updated_at
        sensors_count
        serials_count
      }
      previousReport {
        report {
          id
          sensors_with_telegrams_count
          radio_collected_count
        }
        is_regular
      }
      nextReport {
        id
      }
      receiver
      errors {
        type
        details
        message
      }
      excluded_sensor_groups
      comments {
        report_id
        apartment {
          id
          location
          external_name
          external_number
          external_name2
        }
        sensor {
          id
          external_number
          external_id
          serial_number
          apartment {
            id
            location
            external_name
            external_number
            external_name2
          }
        }
        comment
        comment_operator
      }
    }
  }
`;

const GET_REPORT_SENSORS = gql`
  query getReportSensors($reportId: ID!) {
    reports(ids: [$reportId]) {
      id
      sensors {
        id
        external_number
        serial_number
        actual_serial_number
        original_type
        last_seen
        report_sensor(report_id: $reportId) {
          id
          comment_operator
          has_telegram
          has_measurement
          value {
            id
            captured_at
            value
          }
          is_excluded
        }
        latest_measurement(report_id: $reportId) {
          value
          date
        }
        latest_telegram(report_id: $reportId) {
          id
          header {
            device
          }
          is_decrypted
          error
          errorDescription
        }
        apartment {
          id
          external_number
          location
        }
      }
    }
  }
`;

const CLOSE_REPORT = gql`
  mutation closeReport($id: ID!) {
    changeReportStatus(id: $id, status: 20) {
      id
      status
      updated_at
    }
  }
`;

const OPEN_REPORT = gql`
  mutation closeReport($id: ID!) {
    changeReportStatus(id: $id, status: 1) {
      id
      status
      updated_at
    }
  }
`;

const SET_REPORT_SENSOR_IS_EXCLUDED = gql`
  mutation setReportSensorIsExcluded($reportId: ID!, $sensorId: ID!, $isExcluded: Boolean!) {
    setReportSensorIsExcluded(report_id: $reportId, sensor_id: $sensorId, is_excluded: $isExcluded) {
      id
    }
  }
`;

const TOGGLE_REPORT_EXCLUDED_SENSOR_GROUP = gql`
  mutation toggleReportExcludedSensorGroup($reportId: ID!, $excludedSensorGroup: String!) {
    toggleReportExcludedSensorGroup(reportId: $reportId, excluded_sensor_group: $excludedSensorGroup) {
      id
    }
  }
`;

const REFRESH_BUILDING = gql`
  mutation refreshBuilding($id: ID!, $reportId: ID!) {
    refreshBuilding(id: $id, reportId: $reportId) {
      id
    }
  }
`;

const REFRESH_REPORT_TELEGRAMS = gql`
  mutation asyncReprocessReportTelegrams($reportId: ID!) {
    asyncReprocessReportTelegrams(reportId: $reportId)
  }
`;

const SENSOR_TELEGRAM_SEARCH = gql`
  mutation sensorTelegramSearch(
    $query: SearchQuery!
    $serialNumber: Int!
    $capturedStartDate: DateTime!
    $capturedEndDate: DateTime!
  ) {
    sensorTelegramSearch(
      query: $query
      serialNumber: $serialNumber
      capturedStartDate: $capturedStartDate
      capturedEndDate: $capturedEndDate
    ) {
      rows {
        id
        captured_at
        processed_at
        operator {
          id
          email
        }
        gateway {
          id
          serial_number
          imei
          file_prefix
          manufacturer
        }
      }
      count
    }
  }
`;

const GET_TELEGRAM_BY_ID = gql`
  query getTelegram($id: ID!) {
    telegram(id: $id) {
      id
      decrypted
      header {
        L
        C
        CI
        serial
        manufacturer
        status
        version
        accNr
        decryption
        device
        device_text
        configuration
      }
      errorDescription
      content
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toggleSensorGroup: {
    margin: theme.spacing(1),
  },
  downloadSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

const ReportCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ReportCardList = styled(List)`
  flex: 1;
`;

const ReportCardContent = styled(CardContent)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const sortLatestMeasurementValue = (rowA, rowB) => {
  const getValue = (row) => {
    const { latest_telegram: telegram } = row;
    if (telegram) {
      const { device } = telegram?.header;
      if (device === '1a') {
        // sort by error code if it's smoke detector
        return telegram.error ?? -1;
      }
    }
    const value = fp.get('latest_measurement.value', row);

    return fp.isNil(value) ? -1 : value;
  };

  return getValue(rowA) - getValue(rowB);
};

const sortHandMeasurementValue = (rowA, rowB) => {
  const getValue = (row) => {
    const value = fp.get('value.value', row);

    return fp.isNil(value) ? -100 : value;
  };

  return getValue(rowA) - getValue(rowB);
};

export const reportStatusLabel = (t, status) =>
  fp.get(status, {
    0: t('report:New'),
    1: t('report:Open'),
    5: t('report:In Progress'),
    10: t('report:Submitted'),
    20: t('report:Confirmed'),
    30: t('report:Marked Wrong'),
  }) || t('report:New');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TelegramsDialog = ({ report, sensor, onClose }) => {
  const { t } = useTranslation(['report']);
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const client = useApolloClient();
  const [telegramsWithContent, setTelegramsWithContent] = useState({});
  const capturedStartDate = moment.utc(report.reading_date).startOf('month');
  const capturedEndDate = moment
    .utc(report.reading_date)
    .add(1, 'year')
    .endOf('month');
  const [sensorTelegramSearch] = useMutation(SENSOR_TELEGRAM_SEARCH);

  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: t('report:Captured at'),
      field: 'captured_at',
      render: (rowData) =>
        moment.utc(rowData.captured_at).format('lll'),
      filtering: false,
    },
    {
      title: t('report:Captured by'),
      field: 'operator.email',
      association: 'Operator',
      filter: {
        fields: ['$Operator.email$'],
      },
    },
    {
      title: t('report:Gateway serial number'),
      field: 'gateway.serial_number',
      association: 'Gateway',
      filter: {
        fields: ['$Gateway.serial_number$'],
      },
    },
    {
      title: t('report:Gateway manufacturer'),
      field: 'gateway.manufacturer',
      association: 'Gateway',
      filter: {
        fields: ['$Gateway.manufacturer$'],
      },
    },
    {
      title: t('report:Gateway imei'),
      field: 'gateway.imei',
      association: 'Gateway',
      filter: {
        fields: ['$Gateway.imei$'],
      },
    },
    {
      title: t('report:Gateway file prefix'),
      field: 'gateway.file_prefix',
      association: 'Gateway',
      filter: {
        fields: ['$Gateway.file_prefix$'],
      },
    },
    {
      title: t('report:Gateway ID'),
      field: 'gateway.id',
      association: 'Gateway',
      filter: {
        fields: ['$Gateway.id$'],
      },
    },
  ];
  const columnFieldToColumnMap = columns.reduce(
    (result, column) => ({ ...result, [column.field]: column }),
    {},
  );

  return (
    <Dialog
      fullScreen
      open={!!sensor}
      onClose={onClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('report:Telegrams for')}{' '}
            {fp.get('actual_serial_number', sensor)}
            {' | '}
            {capturedStartDate.format('MMMM YYYY')}
            {' - '}
            {capturedEndDate.format('MMMM YYYY')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box m={2}>
        <Table
          tableRef={tableRef}
          title=""
          tableId={'reading-telegrams'}
          columns={columns}
          options={{
            toolbar: true,
            search: false,
            filtering: true,
            pageSize,
          }}
          data={data(
            columnFieldToColumnMap,
            sensorTelegramSearch,
            (result) => result.data.sensorTelegramSearch,
            setPageSize,
            [],
            false,
            {
              serialNumber: Number(sensor.actual_serial_number),
              capturedStartDate: capturedStartDate.toISOString(),
              capturedEndDate: capturedEndDate.toISOString(),
            },
          )}
          onRowClick={async (_, rowData) => {
            // All of this code is because there is a bug with queries
            // and material-table detailPanel. If we try to useQuery or
            // useLazyQuery to fetch data for the detailPanel, the panel
            // will close itself and it is needed a second click to open it.
            const telegramId = rowData.id;
            let telegramData = telegramsWithContent[telegramId];

            if (!telegramData) {
              const { data } = await client.query({
                query: GET_TELEGRAM_BY_ID,
                variables: { id: telegramId },
                fetchPolicy: 'network-only',
              });
              setTelegramsWithContent({
                ...telegramsWithContent,
                [telegramId]: data.telegram,
              });
              telegramData = data.telegram;
            }

            const sortedDataIndex = tableRef.current.dataManager.sortedData.findIndex(
              (row) => row.id === telegramId
            );
            tableRef.current.onToggleDetailPanel(
              [sortedDataIndex],
              () => (
                <Box m={6}>
                  <TelegramDetailPanel telegram={telegramData} t={t} />
                </Box>
              ),
            );
          }}
          detailPanel={[
            {
              disabled: true,
              icon: () => null,
              render: (rowData) => {
                return null;
              },
            },
          ]}
        />
      </Box>
    </Dialog>
  );
};

const RECEIVER_TO_LABEL = {
  sk_soft: 'SK-Soft',
};

const getIsReportSensorExcluded = (sensor) => !!sensor.report_sensor?.is_excluded;

const ReportSensorsTable = ({ report }) => {
  const { t } = useTranslation(['report']);
  const classes = useTableStyles();
  const [useMeData, meDispatch] = useMe();
  const me = useMeData || {status: null};
  const {
    loading: reportSensorsLoading,
    error: reportSensorsError,
    data: reportSensorsData,
  } = useQuery(GET_REPORT_SENSORS, {
    variables: {
      reportId: report.id,
    },
  });
  const [setReportSensorIsExcluded] = useMutation(
    SET_REPORT_SENSOR_IS_EXCLUDED,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_REPORT,
          variables: {
            id: report.id,
          },
        },
        {
          query: GET_REPORT_SENSORS,
          variables: {
            reportId: report.id,
          },
        },
      ],
    });
  const [chosenReportSensor, setChosenReportSensor] = useState(null);

  if (reportSensorsError) {
    console.error(reportSensorsError);
  }

  const reportSensors =
    fp.get('reports.0.sensors', reportSensorsData) || [];

  const onTelegramsDialogClose = () => setChosenReportSensor(null);

  return (
    <React.Fragment>
      {!!chosenReportSensor ? (
        <TelegramsDialog
          report={report}
          sensor={chosenReportSensor}
          onClose={onTelegramsDialogClose}
        />
      ) : null}
      <Table
        title={
          <div>
            <MoneyIcon className={classes.titleIcon} />
            {t('report:Readings')}
          </div>
        }
        tableId={`report-${report.id}-readings`}
        columns={[
          {
            title: t('report:Serial Number'),
            field: 'serial_number',
            type: 'numeric',
            defaultSort: 'desc',
            filtering: false,
            render: ({ serial_number, actual_serial_number }) => {
              const url = `/telegrams?${TelegramModel.serialNumberParameter}=${actual_serial_number}`;
              return (
                <Link to={url} target="_blank">
                  {serial_number}
                </Link>
              );
            },
          },
          {
            title: t('report:ID4'),
            field: 'external_number',
            filtering: false,
          },
          {
            title: t('report:User number'),
            field: 'apartment.external_number',
            filtering: false,
          },
          {
            title: t('report:Location'),
            field: 'apartment.location',
            filtering: false,
          },
          {
            title: t('report:Radio Measurement Value'),
            customSort: sortLatestMeasurementValue,
            filtering: false,
            render: (rowData) => {
              const { latest_telegram: telegram } = rowData;
              if (telegram) {
                const { device } = telegram?.header;
                if (device === '1a') {
                  // return error code and description if it's smoke detector
                  return `${telegram.errorDescription ?? 'OK'} (${telegram.error})`;
                }
              }

              return rowData.latest_measurement?.value;
            },
          },
          {
            title: t('report:Radio Measurement Date'),
            field: 'latest_measurement.date',
            type: 'datetime',
            filtering: false,
            render: (rowData) =>
              rowData.latest_measurement
                ? moment(rowData.latest_measurement.date).format('LL')
                : '',
          },
          {
            title: t('report:Hand Measurement Value'),
            field: 'report_sensor.value.value',
            customSort: sortHandMeasurementValue,
            filtering: false,
          },
          {
            title: t('report:Hand Measurement Date'),
            field: 'report_sensor.value.captured_at',
            type: 'datetime',
            filtering: false,
            render: (rowData) =>
              rowData.report_sensor.value?.value
                ? moment(rowData.report_sensor.value.captured_at).format('LL')
                : '',
          },
          {
            title: t('report:Sensor Type'),
            field: 'original_type',
          },
          {
            title: t('report:Telegram exists'),
            field: 'latest_telegram',
            type: 'boolean',
            render: (rowData) => {
              return rowData.latest_telegram !== null ? <Check /> : <Remove />;
            },
          },
          {
            title: t('report:Telegram decrypted'),
            field: 'latest_telegram.is_decrypted',
            type: 'boolean',
          },
          {
            title: t('report:Measurement exists'),
            field: 'latest_measurement',
            type: 'boolean',
            render: (rowData) => {
              return rowData.latest_measurement !== null ? <Check /> : <Remove />;
            },
          },
          {
            title: t('report:Operator comment'),
            field: 'report_sensor.comment_operator',
          },
          {
            title: t('report:Last Seen'),
            field: 'last_seen',
            type: 'datetime',
            render: (rowData) =>
              rowData?.last_seen
                ? moment(rowData.last_seen).format('LL')
                : '',
          },
        ]}
        isLoading={reportSensorsLoading}
        data={reportSensors}
        options={{
          toolbar: true,
          filtering: true,
        }}
        actions={[
          (rowData) => ({
            icon: ViewListIcon,
            tooltip: t('report:Show telegrams'),
            isFreeAction: false,
            onClick: () => {
              setChosenReportSensor(rowData);
            },
            hidden: !fp.get('report_sensor.has_telegram', rowData),
          }),
          !UserModel.roleGatewayOperator(me.role) && ((rowData) => ({
            icon: () => (
              <Checkbox
                checked={!getIsReportSensorExcluded(rowData)}
              />
            ),
            tooltip: t('report:Include'),
            onClick: async () => {
              await setReportSensorIsExcluded({
                variables: {
                  reportId: report.id,
                  sensorId: rowData.id,
                  isExcluded: !getIsReportSensorExcluded(rowData),
                },
              });
            },
          })),
          !UserModel.roleGatewayOperator(me.role) && {
            icon: () => <Checkbox
              checked={!reportSensors.find((sensor) => getIsReportSensorExcluded(sensor))}
            />,
            tooltip: t('report:Include all'),
            isFreeAction: true,
            onClick: async () => {
              await Promise.all(reportSensors.map(async (sensor) => {
                if (getIsReportSensorExcluded(sensor)) {
                  await setReportSensorIsExcluded({
                    variables: {
                      reportId: report.id,
                      sensorId: sensor.id,
                      isExcluded: false,
                    },
                  });
                }
              }));
            },
          }
        ]}
      />
    </React.Fragment>
  );
};

function Content({ match }) {
  const [useMeData, meDispatch] = useMe();
  const me = useMeData || {status: null};
  const { loading, error, data } = useQuery(GET_REPORT, {
    variables: {
      id: match.params.id,
    },
  });
  const [closeReport] = useMutation(CLOSE_REPORT);
  const [openReport] = useMutation(OPEN_REPORT);
  const [
    toggleReportExcludedSensorGroup,
    { loading: toggleExcludedSensorGroupLoading, error: toggleExcludedSensorGroupError },
  ] = useMutation(TOGGLE_REPORT_EXCLUDED_SENSOR_GROUP, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REPORT,
        variables: {
          id: match.params.id,
        },
      },
      {
        query: GET_REPORT_SENSORS,
        variables: {
          reportId: match.params.id,
        },
      },
    ],
  });
  const [refreshBuilding, { loading: refreshLoading, error: refreshError }] =
    useMutation(REFRESH_BUILDING, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_REPORT,
          variables: {
            id: match.params.id,
          },
        },
        {
          query: GET_REPORT_SENSORS,
          variables: {
            reportId: match.params.id,
          },
        },
      ],
    });
  const [
    reprocessReportTelegrams,
    { loading: reprocessTelegramLoading, error: reprocessTelegramError },
  ] = useMutation(REFRESH_REPORT_TELEGRAMS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REPORT,
        variables: {
          id: match.params.id,
        },
      },
      {
        query: GET_REPORT_SENSORS,
        variables: {
          reportId: match.params.id,
        },
      },
    ],
  });
  const { t } = useTranslation(['report']);
  const classes = useStyles();
  const isLoading =
    loading || refreshLoading || reprocessTelegramLoading || toggleExcludedSensorGroupLoading;

  if (isLoading) {
    return <CircularProgress />;
  }

  const presentError = error || refreshError || reprocessTelegramError || toggleExcludedSensorGroupError;
  if (presentError) {
    console.error(presentError);
    return <div>Error</div>;
  }

  const report = fp.first(data.reports.map(ReportModel.withBuildingData));

  if (!report) {
    return <div>{t('report:Report not found')}</div>;
  }

  const onToggleIncludedSensorGroupClick = (excludedSensorGroup) => {
    toggleReportExcludedSensorGroup({
      variables: {
        reportId: report.id,
        excludedSensorGroup,
      },
    });
  };

  const { id, building, receiver, errors } = report;
  const { external_id, city, street, house_number } = building ?? {};

  const { previousReport } = report;
  const previousReportId = previousReport?.is_regular && previousReport.report.id;
  const radioCollectedCountDiff = report.radio_collected_count - previousReport?.report.radio_collected_count;
  const sensorsWithTelegramsCountDiff = report.sensors_with_telegrams_count - previousReport?.report.sensors_with_telegrams_count;
  const nextReportId = report.nextReport?.id;

  const hasErrors = !!errors.length;

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Grid container spacing={3}>
          <Grid item xs>
            <ReportCard className={classes.reportCard}>
              <CardHeader
                avatar={<AssignmentIcon />}
                title={t('report:Report {{id}}', { id })}
                titleTypographyProps={{ variant: 'h5' }}
                subheader={reportStatusLabel(t, report.status)}
              />
              <ReportCardContent>
                <ReportCardList dense>
                  {report.status === 0 && report.operator && <ListItem>
                    <Button
                      size="small"
                      color="primary"
                      disabled={report.status === 1}
                      onClick={() => {
                        openReport({
                          variables: {
                            id,
                          },
                        });
                      }}>
                      {t('report:Send it to Operator')}
                    </Button>
                  </ListItem>
                  }

                  <ListItem>
                    {t('report:Operator')}: {report.operator?.email}
                  </ListItem>
                  {!!report.comment_operator && (
                    <ListItem>
                      {t('report:Operator comment')}: {report.comment_operator}
                    </ListItem>
                  )}
                  {!!report.comment && (
                    <ListItem>
                      {t('report:Comment')}: {report.comment}
                    </ListItem>
                  )}
                  <ListItem>
                    {t('report:Sensors')}: {report.sensors_count}
                  </ListItem>
                  <ListItem>
                    {t('report:Creation Date')}:{' '}
                    {moment.utc(report.created_at).format('LLL')}
                  </ListItem>
                  <ListItem>
                    {t('report:Update Date')}:{' '}
                    {moment.utc(report.updated_at).format('LLL')}
                  </ListItem>
                  <ListItem>
                    {t('report:Submission Date')}:{' '}
                    {report.date_submitted
                      ? moment.utc(report.date_submitted).format('LLL')
                      : ''}
                  </ListItem>
                  <ListItem>
                    {t('report:Reading Date')}:{' '}
                    {moment.utc(report.reading_date).format('MMMM YYYY')}
                  </ListItem>
                  <ListItem>
                    {t('report:Set Date')}:{' '}
                    {report.set_date ?
                      moment.utc(report.set_date).format('MMMM YYYY')
                      : ''}
                  </ListItem>
                  {previousReportId && (
                    <ListItem>
                      {t('report:Previous')}:&nbsp;
                      <Link to={`/reports/${previousReportId}`}>
                        {t('report:Report {{id}}', { id: previousReportId })}
                      </Link>
                    </ListItem>
                  )}
                  {nextReportId && (
                    <ListItem>
                      {t('report:Next')}:&nbsp;
                      <Link to={`/reports/${nextReportId}`}>
                        {t('report:Report {{id}}', { id: nextReportId })}
                      </Link>
                    </ListItem>
                  )}
                  {receiver && (
                    <ListItem>
                      {t('report:Receiver')}: {RECEIVER_TO_LABEL[receiver]}
                    </ListItem>
                  )}
                </ReportCardList>
                <Box>
                {!UserModel.roleGatewayOperator(me.role) && <CardActions className={classes.reportCardActions}>
                    <Button
                      size="small"
                      color="primary"
                      // disabled={hasErrors}
                      onClick={() => downloadCsv(
                        data,
                        (
                          building
                            ? [
                              external_id?.replace(/^0+/, ''),
                              city,
                              street + ' ' + house_number,
                              'ReadingsReport',
                              id,
                            ].join('_')
                            : id
                        ) + '_' + moment().format('YYYYMMDDHHmmss') + '.csv',
                        `/report-csv?reportId=${id}`,
                      )}>
                      {t('report:Download')}
                    </Button>
                     <IfenaButton
                      size="small"
                      color="primary"
                      to={`${match.url}/edit`}>
                      {t('report:Edit')}
                    </IfenaButton>
                    <Button
                      size="small"
                      color="primary"
                      disabled={report.status === 20}
                      onClick={() => {
                        closeReport({
                          variables: {
                            id,
                          },
                        });
                      }}>
                      {t('report:Confirm')}
                    </Button>
                  </CardActions>}
                  {hasErrors && (
                    <>
                      <ErrorsList errors={errors} />
                      <ResolveButton reportId={id} />
                    </>
                  )}
                </Box>
              </ReportCardContent>
            </ReportCard>
          </Grid>
          <Grid item xs>
            <ReportCard>
              <CardHeader
                avatar={<PieChartIcon />}
                title={t('report:Statistics')}
                titleTypographyProps={{ variant: 'h5' }}
              />
              <ReportCardContent>
                <ReportCardList dense>
                  <ListItem>
                    {t('report:Sensor %')}:{' '}
                    {(report.sensor_completion * 100).toFixed(1)}% (
                    {t(
                      'report:{{colledCount}} of {{sensorsCount}}',
                      {
                        colledCount: report.radio_collected_count + report.hand_collected_count,
                        sensorsCount: report.sensors_count,
                      },
                    )}
                    )
                  </ListItem>
                  <ListItem>
                    <span>
                      {t('report:Serial %')}:{' '}
                      {(report.serial_completion * 100).toFixed(1)}% (
                      {t('report:{{radioCollectedCount}} of {{serialsCount}}', {
                        radioCollectedCount: report.radio_collected_count,
                        serialsCount: report.serials_count,
                      })}
                      )
                      {previousReport && <>
                        <br />
                        <span style={{ color: 'gray' }}>
                          {t('report:Compared to last report')}:{' '}
                          {radioCollectedCountDiff > 0 && '+'}
                          {radioCollectedCountDiff} (
                          {report.radio_collected_count} - {previousReport.report.radio_collected_count}
                          )
                        </span>
                      </>}
                    </span>
                  </ListItem>
                  <ListItem>
                    <span>
                      {t('report:Telegram %')}:{' '}
                      {(report.telegram_completion * 100).toFixed(1)}% (
                      {t('report:{{telegramCollectedCount}} of {{serialsCount}}', {
                        telegramCollectedCount: report.sensors_with_telegrams_count,
                        serialsCount: report.serials_count,
                      })}
                      )
                      {previousReport && <>
                        <br />
                        <span style={{ color: 'gray' }}>
                          {t('report:Compared to last report')}:{' '}
                          {sensorsWithTelegramsCountDiff > 0 && '+'}
                          {sensorsWithTelegramsCountDiff} (
                          {report.sensors_with_telegrams_count} - {previousReport.report.sensors_with_telegrams_count}
                          )
                        </span>
                      </>}
                    </span>
                  </ListItem>
                  <ListItem>
                    {t('report:Total Telegram %')}:{' '}
                    {(report.total_telegram_completion * 100).toFixed(1)}% (
                    {t('report:{{telegramCollectedCount}} of {{serialsCount}}', {
                      telegramCollectedCount: report.sensors_with_total_telegrams_count,
                      serialsCount: report.serials_count,
                    })}
                    )
                  </ListItem>
                  <ListItem>
                    {t('report:Hand Collected Count')}:{' '}
                    {report.hand_collected_count}
                  </ListItem>
                </ReportCardList>
              </ReportCardContent>
              <Divider className={classes.divider} />
              <CardHeader
                avatar={<PublishIcon />}
                title={t('report:Last exports')}
                titleTypographyProps={{ variant: 'h5' }}
              />
              <ReportCardContent>
                <ReportCardList dense>
                  {Object.keys(report.last_exports).map((ftpUser, index) => {
                    const lastExportDate = report.last_exports[ftpUser];
                    return (
                      <ListItem key={index}>
                        {`${ftpUser}: ${lastExportDate ?
                          moment(lastExportDate).format('LLL') : t('report:never exported')
                          }`}
                      </ListItem>
                    );
                  })}
                </ReportCardList>
                <CardActions>
                  {!UserModel.roleGatewayOperator(me.role) && <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (
                        !window.confirm(
                          t(
                            'report:This operation will schedule re-processing all measurements of telegrams from this report. Are you sure?',
                          ),
                        )
                      ) {
                        return;
                      }
                      reprocessReportTelegrams({
                        variables: {
                          reportId: id,
                        },
                      });
                    }}>
                    {t('report:Refresh related Telegrams')}
                  </Button>}
                </CardActions>
              </ReportCardContent>
            </ReportCard>
          </Grid>
          <Grid item xs>
            <ReportCard className={classes.reportCard}>
              <CardHeader
                avatar={<BusinessIcon />}
                title={`${street} ${house_number}`}
                titleTypographyProps={{ variant: 'h5' }}
                subheader={`${building.postalcode} ${city}`}
              />
              <ReportCardContent>
                <ReportCardList dense>
                  {!!building.alternate_address && (
                    <ListItem>
                      {t('report:Alternate Address')}:{' '}
                      {building.alternate_address}
                    </ListItem>
                  )}
                  <ListItem>
                    {t('report:Update Date')}:{' '}
                    {moment(building.updated_at).format('LLL')}
                  </ListItem>
                  <ListItem>
                    {t('report:Set Date')}:{' '}
                    {moment(building.set_date).format('MMMM YYYY')}
                  </ListItem>
                  <ListItem>
                    {t('report:ID1')}: {external_id}
                  </ListItem>
                  {!!building.comment_operator && (
                    <ListItem>
                      {t('report:Operator comment')}:{' '}
                      {building.comment_operator}
                    </ListItem>
                  )}
                  {!!building.comment && (
                    <ListItem>
                      {t('report:Comment')}: {building.comment}
                    </ListItem>
                  )}
                  <ListItem>
                    {t('report:Sensors')}: {building.sensors_count}
                  </ListItem>
                  <ListItem>
                    {t('report:Serials')}: {building.serials_count}
                  </ListItem>
                  <ListItem>
                    {t('report:Building sensors')}:{' '}
                    {building.building_sensors_count}
                  </ListItem>
                  <ListItem>
                    {t('report:Apartment sensors')}:{' '}
                    {building.apartment_sensors_count}
                  </ListItem>
                </ReportCardList>
                <CardActions>
                  <IfenaButton
                    size="small"
                    color="primary"
                    to={`/buildings/${building.id}`}>
                    {t('report:Go to Building')}
                  </IfenaButton>
                  <Button
                    size="small"
                    color="primary"
                    href={`https://maps.google.com/?q=${street} ${house_number} ${building.postalcode} ${city}`}
                    target="_blank">
                    {t('report:Open map')}
                  </Button>
                  {!UserModel.roleGatewayOperator(me.role) && <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      refreshBuilding({
                        variables: {
                          id: building.id,
                          reportId: id,
                        },
                      });
                    }}>
                    {t('report:Refresh')}
                  </Button>}
                </CardActions>
              </ReportCardContent>
            </ReportCard>
          </Grid>
        </Grid>
      </Box>

      {!UserModel.roleGatewayOperator(me.role) && <Box mb={2}>
        <Card>
          <Box component="span" p={2}>
            {t('report:Included sensor groups')}:
          </Box>
          <ToggleButtonGroup>
            {Object.keys(t('sensor:types')).map((type) => (
              <ToggleButton
                key={`filter-${type}`}
                variant="contained"
                size="small"
                className={classes.toggleSensorGroup}
                onClick={() => onToggleIncludedSensorGroupClick(type)}
                selected={report.excluded_sensor_groups.indexOf(type) === -1}
              >
                {type}
              </ToggleButton>
            ))}
            <ToggleButton
              variant="contained"
              size="small"
              className={classes.toggleSensorGroup}
              onClick={() => onToggleIncludedSensorGroupClick('inactive')}
              selected={report.excluded_sensor_groups.indexOf('inactive') === -1}
            >
              {t('report:Inactive devices')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Card>
      </Box>}

      <Box>
        <ReportSensorsTable report={report} />
      </Box>
      <Box>
        <CommentsTable
          comments={fp.get('comments', report)}
          building={fp.get('building', report)}
          isLoading={loading}
          reportId={report.id}
        />
      </Box>
    </div>
  );
}

export default function Report({ match }) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/edit`} component={NewReport} />
    </Switch>
  );
}
