import React from 'react';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RouterIcon from '@material-ui/icons/Router';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Link from '../../components/Link';

const GatewayOperator = () => {
    const { t } = useTranslation(['translations', 'layout']);

    return (
        <React.Fragment>
            <Link to="/reports">
                <ListItem button key={t('layout:/reports')}>
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('layout:/reports')} />
                </ListItem>
            </Link>
        </React.Fragment>
    );
};
export default GatewayOperator;
